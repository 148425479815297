.navdesktop_link {
  height: 100%;
  padding: 0 12px;
  border-bottom: 1px solid transparent;
  transition: var(--transition);

  & > a {
    height: inherit;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: var(--color-white);
  }

  &:hover {
    background-color: rgba(163, 73, 163, 0.2);
  }

  &.active {
    border-bottom: 1px solid var(--color-primary);
  }
}
