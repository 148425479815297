.header_container {
  width: 100%;
  height: 75px;
  position: fixed;
  background: rgba(0, 0, 0, 0.9);
  z-index: 999;

  .header-dl {
    opacity: 0;
    pointer-events: none;
    transition: var(--transition);
  }

  .show {
    opacity: 1;
    pointer-events: unset;
  }
}

.footer_container {
  height: 120px;
  background-color: #282828;

  a {
    color: inherit;
    line-height: 0;

    &:hover {
      color: var(--color-light);
    }
  }
}

@media screen and (max-width: 600px) {
  .header_container {
    height: 64px;
  }

  .footer_container {
    p {
      font-size: 13px;
    }
  }
}
