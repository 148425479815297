.btn {
  padding: 12px 20px;
  width: max-content;
  height: fit-content;
  display: inline-block;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  color: var(--color-accent);
  background: var(--color-primary);
  border: 1px solid var(--color-primary);
  border-radius: 3rem;
  transition: var(--transition);
  cursor: pointer;

  &:hover {
    color: var(--color-accent);
    background: var(--color-primary-variant);
    border-color: transparent;
  }
}

.btn-outlined {
  color: var(--color-primary);
  background: transparent;

  &:hover {
    color: var(--color-primary-variant);
    background: transparent;
    border-color: var(--color-primary-variant);
  }
}
