nav {
  height: 100%;
  justify-content: center;
}

.navmobile_link {
  width: 100%;
  padding-left: 32px;
  background-color: transparent;
  transition: var(--transition);

  & > a {
    height: 48px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: var(--color-white);
  }

  &:hover {
    background-color: rgba(163, 73, 163, 0.2);
  }

  &.active {
    padding-left: calc(32px - 3px);
    border-left: 3px solid var(--color-primary);
  }
}
