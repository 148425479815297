@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --color-primary: #a349a3;
  --color-secondary: #f06eaa;
  --color-accent: #181818;
  --color-white: #fff;
  --color-black: #000;

  --color-primary-variant: rgba(163, 73, 163, 0.8);
  --color-secondary-variant: rgba(240, 110, 170, 0.6);
  --color-light: rgba(255, 255, 255, 0.6);
  --color-dark: rgba(0, 0, 0, 0.6);

  --container-width: 84%;
  --transition: all 400ms ease;
  --box-shadow: 15px 15px 38px 0 rgba(0, 0, 0, 0.1) !important;

  --background-popover: rgba(0, 0, 0, 0.9);
}

html {
  scroll-behavior: smooth;
  user-select: none;
}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: "Poppins", sans-serif;
  background: var(--color-accent);
  color: var(--color-white);
  line-height: 1.5;
  zoom: 1 !important;
}

// GENERAL STYLE ----------------------------------------------------------------------

.container {
  margin: 0 auto;
  width: var(--container-width);
  max-width: 1280px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

h1 {
  font-size: 80px;
}

h2 {
  font-size: 64px;
}

h3 {
  font-size: 48px;
}

h4 {
  font-size: 36px;
}

h5 {
  font-size: 30px;
}

h6 {
  font-size: 24px;
}

a {
  color: var(--color-primary);
  transition: var(--transition);

  &:hover {
    color: var(--color-primary-variant);
  }
}

button {
  font-family: "Poppins", sans-serif;
  background: transparent;
}

input,
textarea {
  font-family: "Poppins", sans-serif;
  width: 100%;
  resize: none;
  padding: 24px;
  color: var(--color-white);
  background: var(--color-dark);
  border-bottom: 2px solid var(--color-primary-variant);
}

@media screen and (max-width: 1200px) {
  .container {
    max-width: 1080px;
  }

  h1 {
    font-size: 52px;
  }

  h2 {
    font-size: 44px;
  }

  h3 {
    font-size: 28px;
  }

  h4 {
    font-size: 26px;
  }

  h5 {
    font-size: 25px;
  }

  h6 {
    font-size: 24px;
  }
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 32px;
  }

  h3 {
    font-size: 28px;
  }

  h4 {
    font-size: 26px;
  }

  h5 {
    font-size: 25px;
  }

  h6 {
    font-size: 24px;
  }
}
